.button {
  display: inline-block;
  border: 2px solid var(--white);
  background-color: transparent;
  padding: 10px 20px;
  color: var(--white);
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  transition-duration: 0.25s;
  transition-property: background-color, color;
}
.button:hover {
  background-color: var(--white);
  color: var(--black);
}
.button.-primary {
  background-color: var(--ui-primary-color);
  border: 2px solid var(--ui-primary-color);
  color: var(--black);
}
.button.-primary:hover {
  background-color: var(--ui-primary-hover-color);
  border: 2px solid var(--ui-primary-hover-color);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kYmZvcmdlLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsiYnV0dG9uLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLmJ1dHRvblxuICBkaXNwbGF5OiBpbmxpbmUtYmxvY2tcbiAgYm9yZGVyOiAycHggc29saWQgdmFyKC0td2hpdGUpXG4gIGJhY2tncm91bmQtY29sb3I6IHRyYW5zcGFyZW50XG4gIHBhZGRpbmc6IDEwcHggMjBweFxuICBjb2xvcjogdmFyKC0td2hpdGUpXG4gIGZvbnQtc2l6ZTogMThweFxuICBjdXJzb3I6IHBvaW50ZXJcbiAgdGV4dC1kZWNvcmF0aW9uOiBub25lXG4gIHRyYW5zaXRpb24tZHVyYXRpb246IDAuMjVzXG4gIHRyYW5zaXRpb24tcHJvcGVydHk6IGJhY2tncm91bmQtY29sb3IsIGNvbG9yXG5cbiAgJjpob3ZlclxuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXdoaXRlKVxuICAgIGNvbG9yOiB2YXIoLS1ibGFjaylcblxuICAmLi1wcmltYXJ5XG4gICAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tdWktcHJpbWFyeS1jb2xvcilcbiAgICBib3JkZXI6IDJweCBzb2xpZCB2YXIoLS11aS1wcmltYXJ5LWNvbG9yKVxuICAgIGNvbG9yOiB2YXIoLS1ibGFjaylcblxuICAgICY6aG92ZXJcbiAgICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXVpLXByaW1hcnktaG92ZXItY29sb3IpXG4gICAgICBib3JkZXI6IDJweCBzb2xpZCB2YXIoLS11aS1wcmltYXJ5LWhvdmVyLWNvbG9yKVxuIl19 */