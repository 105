@import ../environment

.navigation-icon
  text-decoration: none

.navigation-icon--pixels
  align-items: center
  display: flex
  justify-content: center

  color: var(--text-color)

  &:hover
    color: var(--text-hover-color)

  &.-cyan
    &:hover
      color: var(--cyan)

  &.-purple
    &:hover
      color: var(--purple)

  & svg
    +dimensions(56px)

    +on-small-screens
      +dimensions(36px)

    +on-extra-small-screens
      +dimensions(28px)