:root {
  --black: rgb(0, 0, 0);
  --black-90p: rgba(0, 0, 0, 0.9);
  --white: rgb(255, 255, 255);
  --cyan: #34d399;
  --purple: #9333ea;
  --ui-primary-color: var(--cyan);
  --ui-primary-hover-color: #10b981;
  --text-color: var(--white);
  --text-shadow-color: #5eead4;
  --text-hover-color: #94a3b8;
  --section-bg-color: #111827;
  --section-gradient-bg: linear-gradient(180deg, rgba(15,23,42,1) 0%, rgba(17,24,39,1) 50%);
  --link-color: #38bdf8;
  --link-hover-color: #0ea5e9;
  --table-bg-color: #1f2937;
  --table-stripe-bg-color: #111827;
}

.link-separator {
  display: inline-block;
  height: 0.75rem;
  width: 2px;
  margin-inline: 8px;
  vertical-align: middle;
  background-color: var(--white);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kYmZvcmdlLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsiLi4vY29uZmlnL3Jvb3QvX2NvbG9ycy5zYXNzIiwibGlua19zZXBhcmF0b3Iuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQztFQUNDO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFFQTtFQUNBO0VBRUE7RUFDQTtFQUNBO0VBRUE7RUFDQTtFQUVBO0VBQ0E7RUFFQTtFQUNBOzs7QUNuQkY7RUFDRTtFQUNBO0VBQ0E7RUFFQTtFQUNBO0VBRUEiLCJzb3VyY2VzQ29udGVudCI6WyJcXDpyb290XG4gIC0tYmxhY2s6IHJnYigwLCAwLCAwKVxuICAtLWJsYWNrLTkwcDogcmdiYSgwLCAwLCAwLCAwLjkpXG4gIC0td2hpdGU6IHJnYigyNTUsIDI1NSwgMjU1KVxuICAtLWN5YW46ICMzNGQzOTlcbiAgLS1wdXJwbGU6ICM5MzMzZWFcblxuICAtLXVpLXByaW1hcnktY29sb3I6IHZhcigtLWN5YW4pXG4gIC0tdWktcHJpbWFyeS1ob3Zlci1jb2xvcjogIzEwYjk4MVxuXG4gIC0tdGV4dC1jb2xvcjogdmFyKC0td2hpdGUpXG4gIC0tdGV4dC1zaGFkb3ctY29sb3I6ICM1ZWVhZDRcbiAgLS10ZXh0LWhvdmVyLWNvbG9yOiAjOTRhM2I4XG5cbiAgLS1zZWN0aW9uLWJnLWNvbG9yOiAjMTExODI3XG4gIC0tc2VjdGlvbi1ncmFkaWVudC1iZzogbGluZWFyLWdyYWRpZW50KDE4MGRlZywgcmdiYSgxNSwyMyw0MiwxKSAwJSwgcmdiYSgxNywyNCwzOSwxKSA1MCUpXG5cbiAgLS1saW5rLWNvbG9yOiAjMzhiZGY4XG4gIC0tbGluay1ob3Zlci1jb2xvcjogIzBlYTVlOVxuXG4gIC0tdGFibGUtYmctY29sb3I6ICMxZjI5MzdcbiAgLS10YWJsZS1zdHJpcGUtYmctY29sb3I6ICMxMTE4MjciLCJAaW1wb3J0IC4uL2Vudmlyb25tZW50XG5cbi5saW5rLXNlcGFyYXRvclxuICBkaXNwbGF5OiBpbmxpbmUtYmxvY2tcbiAgaGVpZ2h0OiAwLjc1cmVtXG4gIHdpZHRoOiAycHhcblxuICBtYXJnaW4taW5saW5lOiA4cHhcbiAgdmVydGljYWwtYWxpZ246IG1pZGRsZVxuXG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXdoaXRlKSJdfQ== */