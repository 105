.button
  display: inline-block
  border: 2px solid var(--white)
  background-color: transparent
  padding: 10px 20px
  color: var(--white)
  font-size: 18px
  cursor: pointer
  text-decoration: none
  transition-duration: 0.25s
  transition-property: background-color, color

  &:hover
    background-color: var(--white)
    color: var(--black)

  &.-primary
    background-color: var(--ui-primary-color)
    border: 2px solid var(--ui-primary-color)
    color: var(--black)

    &:hover
      background-color: var(--ui-primary-hover-color)
      border: 2px solid var(--ui-primary-hover-color)
