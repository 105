@import ../environment

=when-fixed
  body.fixed-navbar &
    @content

=when-static
  body:not(.fixed-navbar) &
    @content

.navigation-bar
  width: 100%
  z-index: 3

  transition: background 0.5s ease-in-out

  +when-static
    background-color: var(--black)
    border-bottom: 2px solid var(--white)

  +when-fixed
    position: fixed
    left: 0
    top: 0

  &.is-opaque
    background-color: var(--black-90p)
    border-bottom: 2px solid white

.navigation-bar--content
  align-items: center
  display: flex
  justify-content: space-between
  padding: 10px 0

  color: var(--white)

.navigation-bar--title
  color: var(--white)
  font-size: 32px
  text-decoration: none
  text-shadow: -2px -2px var(--text-shadow-color)

  +on-small-screens
    font-size: 24px

.navigation-bar--links
  align-items: center
  column-gap: 12px
  display: flex

  +on-small-screens
    column-gap: 4px
