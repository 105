@import config/reset
@import ~bootstrap/scss/bootstrap-grid
@import environment
@import font

*
  box-sizing: border-box
  font-family: silkscreen, sans-serif
  font-weight: lighter
  font-style: normal

body
  overflow-x: hidden

.viewport
  padding-inline: 1rem
  margin-inline: auto
  max-width: 1200px

.-centered
  text-align: center

.link
  color: var(--link-color)
  text-decoration: none

  &:hover
    color: var(--link-hover-color)

h1,
h2,
h3,
h4,
h5,
h6
  text-shadow: -1px -1px var(--text-shadow-color)

strong
  font-weight: 700

ul
  list-style-type: square
  margin-left: 16px