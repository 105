@import ../environment

.pc-specs
  color: white
  hyphens: auto
  overflow-x: scroll

.pc-specs--table
  border-collapse: collapse

  tr
    background-color: var(--table-bg-color)

    &:nth-child(even)
      background-color: var(--table-stripe-bg-color)

  th,td
    padding: 20px

    +on-small-screens
      padding: 4px

.pc-specs--total,
.pc-specs--total-price
  font-size: 20px
  font-weight: bold

.pc-specs--price-note
  font-size: 14px
  text-align: center