@import ../environment

.link-separator
  display: inline-block
  height: 0.75rem
  width: 2px

  margin-inline: 8px
  vertical-align: middle

  background-color: var(--white)