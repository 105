\:root
  --black: rgb(0, 0, 0)
  --black-90p: rgba(0, 0, 0, 0.9)
  --white: rgb(255, 255, 255)
  --cyan: #34d399
  --purple: #9333ea

  --ui-primary-color: var(--cyan)
  --ui-primary-hover-color: #10b981

  --text-color: var(--white)
  --text-shadow-color: #5eead4
  --text-hover-color: #94a3b8

  --section-bg-color: #111827
  --section-gradient-bg: linear-gradient(180deg, rgba(15,23,42,1) 0%, rgba(17,24,39,1) 50%)

  --link-color: #38bdf8
  --link-hover-color: #0ea5e9

  --table-bg-color: #1f2937
  --table-stripe-bg-color: #111827