@import ../environment

.hero
  background: rgba(0, 0, 0, 0)
  position: relative
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  column-gap: 40px
  border-bottom: 1px solid white
  hyphens: auto

  +on-small-screens
    flex-direction: column
    row-gap: 20px

.hero--particles
  background-color: #0f172a
  position: absolute
  height: 100vh
  width: 100%

.hero--text-content
  z-index: 2

  +on-small-screens
    align-items: center
    display: flex
    flex-direction: column
    margin-inline: auto
    padding-inline: 1rem

.hero--title
  font-size: 48px
  font-weight: bold
  margin-bottom: 20px
  hyphens: auto
  color: white
  text-shadow: 3px 3px var(--text-shadow-color)

  +on-small-screens
    font-size: 32px
    margin-bottom: 12px
    text-align: center

.hero--subtitle
  font-size: 36px
  line-height: 40px
  color: white

  &.-small
    font-size: 24px
    line-height: 28px

  +on-small-screens
    font-size: 20px
    line-height: 24px
    text-align: center

.hero--buttons
  display: inline-block

.hero--avatar
  position: relative
  z-index: 2

  & img
    +dimensions(250px)
    border: 2px solid #a3a3a3
    border-radius: 50%

    +on-small-screens
      +dimensions(125px)

  &-online-indicator
    content: ""
    display: block
    +dimensions(30px)
    border-radius: 50%
    background-color: #10b981
    position: absolute
    bottom: 25px
    right: 25px

.hero--button
  background-color: #2563eb

  &:not(:last-child)
    margin-right: 20px

.hero--buttons
  display: flex
  align-items: center
  column-gap: 10px
  margin-top: 40px

  +on-small-screens
    margin-top: 20px
