:root {
  --black: rgb(0, 0, 0);
  --black-90p: rgba(0, 0, 0, 0.9);
  --white: rgb(255, 255, 255);
  --cyan: #34d399;
  --purple: #9333ea;
  --ui-primary-color: var(--cyan);
  --ui-primary-hover-color: #10b981;
  --text-color: var(--white);
  --text-shadow-color: #5eead4;
  --text-hover-color: #94a3b8;
  --section-bg-color: #111827;
  --section-gradient-bg: linear-gradient(180deg, rgba(15,23,42,1) 0%, rgba(17,24,39,1) 50%);
  --link-color: #38bdf8;
  --link-hover-color: #0ea5e9;
  --table-bg-color: #1f2937;
  --table-stripe-bg-color: #111827;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kYmZvcmdlLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29uZmlnL3Jvb3QiLCJzb3VyY2VzIjpbIl9jb2xvcnMuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQztFQUNDO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFFQTtFQUNBO0VBRUE7RUFDQTtFQUNBO0VBRUE7RUFDQTtFQUVBO0VBQ0E7RUFFQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiXFw6cm9vdFxuICAtLWJsYWNrOiByZ2IoMCwgMCwgMClcbiAgLS1ibGFjay05MHA6IHJnYmEoMCwgMCwgMCwgMC45KVxuICAtLXdoaXRlOiByZ2IoMjU1LCAyNTUsIDI1NSlcbiAgLS1jeWFuOiAjMzRkMzk5XG4gIC0tcHVycGxlOiAjOTMzM2VhXG5cbiAgLS11aS1wcmltYXJ5LWNvbG9yOiB2YXIoLS1jeWFuKVxuICAtLXVpLXByaW1hcnktaG92ZXItY29sb3I6ICMxMGI5ODFcblxuICAtLXRleHQtY29sb3I6IHZhcigtLXdoaXRlKVxuICAtLXRleHQtc2hhZG93LWNvbG9yOiAjNWVlYWQ0XG4gIC0tdGV4dC1ob3Zlci1jb2xvcjogIzk0YTNiOFxuXG4gIC0tc2VjdGlvbi1iZy1jb2xvcjogIzExMTgyN1xuICAtLXNlY3Rpb24tZ3JhZGllbnQtYmc6IGxpbmVhci1ncmFkaWVudCgxODBkZWcsIHJnYmEoMTUsMjMsNDIsMSkgMCUsIHJnYmEoMTcsMjQsMzksMSkgNTAlKVxuXG4gIC0tbGluay1jb2xvcjogIzM4YmRmOFxuICAtLWxpbmstaG92ZXItY29sb3I6ICMwZWE1ZTlcblxuICAtLXRhYmxlLWJnLWNvbG9yOiAjMWYyOTM3XG4gIC0tdGFibGUtc3RyaXBlLWJnLWNvbG9yOiAjMTExODI3Il19 */