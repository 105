.description--title {
  font-size: 32px;
  text-shadow: -2px -2px var(--text-shadow-color);
}

.description--subtitle {
  font-size: 22px;
}

.description--text {
  margin-top: 20px;
  font-size: 18px;
  line-height: 20px;
}
.description--text .highlighted {
  font-weight: bold;
}
.description--text .icon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
}

.description--buttons {
  align-items: center;
  column-gap: 20px;
  display: flex;
  margin-top: 40px;
}

.description--row + .description--row {
  margin-top: 80px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RvbWluaWMuYmVnZXIvUnVieW1pbmVQcm9qZWN0cy9kYmZvcmdlLmRlL2FwcC9hc3NldHMvYXBwbGljYXRpb24vY29tcG9uZW50cyIsInNvdXJjZXMiOlsiZGVzY3JpcHRpb24uc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7OztBQUVGO0VBQ0U7OztBQUVGO0VBQ0U7RUFFQTtFQUNBOztBQUVBO0VBQ0U7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7OztBQUVKO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7OztBQUdBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuZGVzY3JpcHRpb24tLXRpdGxlXG4gIGZvbnQtc2l6ZTogMzJweFxuICB0ZXh0LXNoYWRvdzogLTJweCAtMnB4IHZhcigtLXRleHQtc2hhZG93LWNvbG9yKVxuXG4uZGVzY3JpcHRpb24tLXN1YnRpdGxlXG4gIGZvbnQtc2l6ZTogMjJweFxuXG4uZGVzY3JpcHRpb24tLXRleHRcbiAgbWFyZ2luLXRvcDogMjBweFxuXG4gIGZvbnQtc2l6ZTogMThweFxuICBsaW5lLWhlaWdodDogMjBweFxuXG4gICYgLmhpZ2hsaWdodGVkXG4gICAgZm9udC13ZWlnaHQ6IGJvbGRcblxuICAmIC5pY29uXG4gICAgZGlzcGxheTogaW5saW5lLWJsb2NrXG4gICAgdmVydGljYWwtYWxpZ246IG1pZGRsZVxuICAgIHdpZHRoOiAyMHB4XG5cbi5kZXNjcmlwdGlvbi0tYnV0dG9uc1xuICBhbGlnbi1pdGVtczogY2VudGVyXG4gIGNvbHVtbi1nYXA6IDIwcHhcbiAgZGlzcGxheTogZmxleFxuICBtYXJnaW4tdG9wOiA0MHB4XG5cbi5kZXNjcmlwdGlvbi0tcm93XG4gICYgKyAmXG4gICAgbWFyZ2luLXRvcDogODBweFxuIl19 */