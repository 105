@import ../environment

.section
  background-color: var(--section-bg-color)
  color: var(--text-color)
  padding: 100px 5%
  z-index: 2

  hyphens: auto

  &.-after-hero
    background: var(--section-gradient-bg)

.section--title
  font-size: 32px
  line-height: 32px
  margin-bottom: 40px