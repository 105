@import ../environment

.footer
  padding: 20px 5%

  background-color: var(--black)
  border-top: 1px solid var(--white)
  color: var(--white)

.footer--inner
  display: flex
  justify-content: space-between
  width: 100%

  +on-small-screens
    align-items: center
    flex-direction: column
    row-gap: 20px
    text-align: center