.description--title
  font-size: 32px
  text-shadow: -2px -2px var(--text-shadow-color)

.description--subtitle
  font-size: 22px

.description--text
  margin-top: 20px

  font-size: 18px
  line-height: 20px

  & .highlighted
    font-weight: bold

  & .icon
    display: inline-block
    vertical-align: middle
    width: 20px

.description--buttons
  align-items: center
  column-gap: 20px
  display: flex
  margin-top: 40px

.description--row
  & + &
    margin-top: 80px
